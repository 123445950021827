

.container {
  position: relative;
  top: 111px;
  height: auto;
  min-height: 500px;
  max-width: 500px;
  min-width: 350px;
  background: #e4e4e4;
  border-radius: 20px;
  box-shadow: 4px 3px 7px 2px #00000040;
  padding: 1rem 1rem 1rem 0;
  box-sizing: border-box;
  text-align: center;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.heading h1 {
  transform: rotate(2deg);
  padding: 0.2rem 1.2rem;
  border-radius: 20% 5% 20% 5%/5% 20% 25% 20%;
  background-color: #fcf876;
  font-size: 1.5rem;
}
