body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #3797a4;
  min-height: 70vh;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  color: hsl(198, 1%, 29%);
  font-family: 'Roboto', 'Trebuchet MS', sans-serif;
  font-size: 130%;
}

button {
  position: relative;
  padding: 5px;
  margin-left: 5px;
  border: none;
  text-decoration: none;
  background: none;
}

#root {
  width: 100%;
}