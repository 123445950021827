
.location {
    text-align: left;
    align-items: left;
    margin-left: 1.6rem;
}

.location-button {
    position: relative;
    padding: 2px 7px;
    border: 1px solid #bdba64;
    margin-top: 5px;
    text-decoration: none;
    border-radius: 5px;
    background-color: #fcf876;
  }