
.form {
  padding-left: 0.7rem;
}

.form input {
    box-sizing: border-box;
    background-color: transparent;
    padding: 0.3rem;
    border-bottom-right-radius: 15px 3px;
    border-bottom-left-radius: 3px 15px;
    border: solid 3px transparent;
    border-bottom: dashed 3px #3797a4;
    font-family: 'Itim', sans-serif;
    font-size: 1.6rem;
    color: hsla(226, 100%, 18%, 0.7);
    width: 55%;
    margin: 20px 0 20px 12%;
  }
  
  .add-span {
    background: #f1f5f8;
    display: block;
    padding: 3px 15px;
    border-radius: 5px;
    border: 2px solid hsl(198, 1%, 29%);
  }